// src/components/MainSection.js
import React from 'react';
import './MainSection.css';

const links = [
  { url: "xoso66001.com", img: "/images/1.png" },
  { url: "xoso66002.com", img: "/images/2.png" },
  { url: "xoso66003.com", img: "/images/3.png" },
  { url: "xoso66004.com", img: "/images/4.png" },
  { url: "xoso66005.com", img: "/images/5.png" },
  { url: "xoso66006.com", img: "/images/6.png" }
];

const MainSection = () => (
  <div className="main-section">
    <div className="empty-section"></div>
    <div className="left-section">
      <img src="/images/11.png" alt="Left visual" className="left-image" />
    </div>
    <div className="right-section">
      <div className="main-content">
        <img src="/images/lg.png" alt="Main visual" className="main-image" />
      </div>
      <div className="links-section">
        <div className="links-columns">
          <div className="links-column">
            {links.slice(0, 3).map(link => (
              <a href={link.url} key={link.url} target="_blank" rel="noopener noreferrer">
                <img src={link.img} alt={link.url} className="link-image" />
              </a>
            ))}
          </div>
          <div className="links-column">
            {links.slice(3).map(link => (
              <a href={link.url} key={link.url} target="_blank" rel="noopener noreferrer">
                <img src={link.img} alt={link.url} className="link-image" />
              </a>
            ))}
          </div>
        </div>
        <div className="buttons">
          <img src="/images/7.png" alt="CSKH ONLINE 24/7" />
          <img src="/images/8.png" alt="TẢI APP XOSO66" />
          <img src="/images/9.png" alt="HƯỚNG DẪN" />
        </div>
      </div>
    </div>
    <div className="empty-section"></div>
    <div className="footer-text">
      @ 2014-2024 v1.6.2 xoso66 All rights reserved
    </div>
  </div>
);

export default MainSection;
