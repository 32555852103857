// src/App.js
import React from 'react';
import './App.css';
import MainSection from './components/MainSection';

function App() {
  return (
    <div className="App">
      <MainSection />
      <footer className="footer">

      </footer>
    </div>
  );
}

export default App;
